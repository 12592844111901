<template>
  <!-- star -->

  <div class="youjiaopage_wrap">
    <nav2></nav2>
    <banner2 :bannerH="h1" :bannerP="p1"></banner2>

    <!-- 上部分 -->
    <div class="talk_top">
      <div class="talk_top_cont">
        <div class="talk_top_cont_l">
          <h1>联系我们</h1>
          <p>contact us</p>
        </div>
        <div class="talk_top_cont_c">
          <p>电话：18600235930</p>
          <p>地址：北京市怀柔区开发路33号</p>
          <p>邮箱：sales@zhangshitech.com</p>
        </div>
      </div>
    </div>
    <!-- 下部分 -->
    <div id="container"></div>
    <footer2></footer2>

    <!-- end -->
  </div>
</template>

<script>
import footer2 from "../components/footer.vue";
import banner2 from "../components/banner.vue";
import nav2 from "../components/nav.vue";
import AMapLoader from "@amap/amap-jsapi-loader";
export default {
  name: "keji",
  components: {
    footer2,
    nav2,
    banner2,
  },
  data() {
    return {
      h1: "与我们联系",
      p1: "",
      longitude: "116.639385",
      Latitude: "40.293101",
      mapInit() {
        AMapLoader.load({
          key: "309502df8f9cb4b2e0a378c583748158", // 申请好的Web端开发者Key，首次调用 load 时必填
          version: "2.0", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
          plugins: ["AMap.Marker"], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
        })
          .then((AMap) => {
            let map = new AMap.Map("container", {
              zoom: 13, //级别
              center: [116.639385, 40.293101], //中心点坐标
            });
            if (
              this.longitude === "116.639385" &&
              this.Latitude === "40.293101"
            ) {
              let trafficLayer = new AMap.Marker({
                position: new AMap.LngLat(this.longitude, this.Latitude),
                title: "北京涨势科技有限公司",
              });
              map.add(trafficLayer);
            }
          })
          .catch((e) => {
            console.log(e);
          });
      },
    };
  },
  mounted() {
    this.mapInit();
  },
  methods: {},
};
</script>
<style scoped>
@charset "utf-8";
/*=========================Reset_start==========================*/
body,
h1,
h2,
h3,
h4,
h5,
h6,
div,
p,
dl,
dt,
dd,
ol,
ul,
li,
form,
table,
th,
td,
a,
img,
span,
strong,
var,
em,
input,
textarea,
select,
option {
  margin: 0;
  padding: 0;
}
/*去内外边距*/
html,
body {
  text-align: left;
}
/*字体 文本居左*/
ul {
  list-style: none;
}
/*去ul li 小点*/
img {
  border: 0 none;
}
/*img IE6 兼容问题*/
input,
select,
textarea {
  outline: 0;
}
/*去除点击蓝框*/
textarea {
  resize: none;
  overflow: auto;
}
/*锁定多行文本框大小 与文字超出出现滚动条*/
table {
  border-collapse: collapse;
  border-spacing: 0;
}
/*表格边框合并 边框间距0*/
th,
strong,
var,
em {
  font-weight: normal;
  font-style: normal;
}
/*特殊文字粗细 样式显示正常*/
a {
  text-decoration: none;
}
/*a标签去下划线*/
.clearfix:after {
  clear: both;
  content: "";
  display: block;
  height: 0;
}
/*清浮动*/
a {
  color: #000000;
}
/*==========================Reset_End===========================*/

.youjiaopage_wrap {
  width: 100%;
  margin: 0 auto;
  height: 1000px;
}
/* 联系我们 */

.talk_top_cont {
  display: flex;
  width: 100%;
  padding: 50px 150px 0;
  margin: 0 auto;
  background: #f3ecec;
}
.talk_top_cont_l {
  width: 350px;
  height: 134px;
  margin-right: 100px;
}
.talk_top_cont_l > h1 {
  font-size: 32px;
  font-weight: 600;
  color: #a8474a;
  line-height: 45px;
}
.talk_top_cont_l p {
  height: 80px;
  font-size: 48px;
  font-weight: 600;
  color: #e0c3c3;
  line-height: 40px;
}
.talk_top_cont_c {
  display: flex;
  font-size: 18px;
  font-weight: 400;
  color: #444444;
  line-height: 40px;
  flex-direction: column;
}
#container {
  width: 1200px;
  height: 480px;
  margin: 50px auto;
  background: #f3ecec;
}
</style>
