<template>
  <div>
    <!-- ---------- -->

    <div class="footer_warp">
      <div class="footer_cont">
        <div>
          <p class="address">联系我们：sales@zhangshitech.com</p>
        </div>
        <div class="footer_log">
          <img src="../assets/img/2966486001.jpg" alt="" />
        </div>
      </div>
      <!-- 下部 -->
      <div class="footer_warp_btm">
        <p>
          版权所有
          ©&ensp;&ensp;北京涨势科技有限公司&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;京ICP备2022013364号
        </p>
      </div>
    </div>

    <!-- --------- -->
  </div>
</template>

<script>
export default {
  name: "Footer",
  data() {
    return {
      arrList: [
        {
          title: "关于企业",
          url: "/jituangaikuo",
          id: "about",
        },
        {
          title: "企业实力",
          url: "/jituangaikuo",
          id: "CultureInfo",
        },
        {
          title: "发展历程",
          url: "/jituangaikuo",
          id: "history",
        },
      ],
      arrList2: [
        {
          title: "金融板块",
          url: "/jinrong",
        },
        {
          title: "科技板块",
          url: "/keji",
        },
      ],
    };
  },

  mounted() {
    let _this = this;
    _this.$nextTick(function () {
      window.addEventListener("scroll", _this.handleScroll);
    });
  },

  methods: {
    // 锚点跳转
    toGuid(path, id) {
      localStorage.setItem("toId", id);
      this.$router.push(path);
      //查找存储的锚点id
      let Id = localStorage.getItem("toId");
      let toElement = document.getElementById(Id);
      //锚点存在跳转
      if (Id) {
        toElement.scrollIntoView();
        localStorage.setItem("toId", "");
      }
    },

    toPages(url) {
      this.$router.push(url);
    },
    toNews(url) {
      this.$router.push(url);
    },
  },
};
</script>
<style scoped>
@charset "utf-8";
/*=========================Reset_start==========================*/
body,
h1,
h2,
h3,
h4,
h5,
h6,
div,
p,
dl,
dt,
dd,
ol,
ul,
li,
form,
table,
th,
td,
a,
img,
span,
strong,
var,
em,
input,
textarea,
select,
option {
  margin: 0;
  padding: 0;
}
/*去内外边距*/
html,
body {
  text-align: left;
}
/*字体 文本居左*/
ul {
  list-style: none;
}
/*去ul li 小点*/
img {
  border: 0 none;
}
/*img IE6 兼容问题*/
input,
select,
textarea {
  outline: 0;
}
/*去除点击蓝框*/
textarea {
  resize: none;
  overflow: auto;
}
/*锁定多行文本框大小 与文字超出出现滚动条*/
table {
  border-collapse: collapse;
  border-spacing: 0;
}
/*表格边框合并 边框间距0*/
th,
strong,
var,
em {
  font-weight: normal;
  font-style: normal;
}
/*特殊文字粗细 样式显示正常*/
a {
  text-decoration: none;
}
/*a标签去下划线*/
.clearfix:after {
  clear: both;
  content: "";
  display: block;
  height: 0;
}
/*清浮动*/
a {
  color: #000000;
}
/*==========================Reset_End===========================*/

.footer_warp {
  width: 100%;
  background: #471f1f;
}
.footer_cont {
  padding: 20px 60px;
  display: flex;
  justify-content: flex-end;
}
.footer_cont div {
  margin-right: 30px;
}
.footer_log img {
  width: 181px;
}
.address {
  font-size: 16px;
  font-weight: 400;
  color: #ffffff;
  line-height: 45px;
}
.footer_cont_r {
  margin-top: 79px;
  margin-left: 70px;
}
.footer_cont_r p {
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  color: #ffffff;
  line-height: 20px;
  margin-top: 17px;
}
.footer_cont_r_border {
  width: 111px;
  height: 109px;
  border: 1px solid #979797;
}
.footer_cont_r_border img {
  width: 95px;
  height: 95px;
  margin: 7px 8px;
}
.footer_warp_btm {
  margin: 0 60px;
  border-top: 1px solid #fbf5e7;
}
.footer_warp_btm p {
  font-size: 12px;
  font-weight: 400;
  color: #a3a3a3;
  line-height: 48px;
}
</style>
