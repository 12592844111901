<template>
  <div class="GroupOverview">
    <daohang></daohang>
    <banner2 :bannerH="h1" :bannerP="P1"></banner2>
    <!-- 关于企业 -->
    <div class="about" id="about">
      <div class="about-top">
        <div class="about-top-l">
          <p>
            &emsp;&emsp;北京涨势科技有限公司（简称“涨势科技”）成立于北京。涨势科技是一家深耕金融行业的科技公司。公司致力于推动大数据、云计算、人工智能等新兴技术在专业领域里的应用。针对各类行业特点，涨势科技开发了解决行业痛点的专业级别的智能分析方法和分析模型，为投资公司，银行等各类企业和机构在数据结构化、商机营销、舆情分析、以及智能投顾、综合监控、客户管理等决策方面提供精准信息分析服务。
          </p>
          <br />
          <p>
            &emsp;&emsp;涨势科技的团队在资管行业服务多年，为帮助金融从业人员快速地改善业务流程,大幅缩短工作周期,涨势科技与国内一家专注于企业级RPA技术的人工智能公司——金智维信息科技有限公司合作。以“RPA+AI+大数据+资管经验”为核心理念，为金融行业制定更适用的业务解决方案。致力于做最专业的资管行业解决方案服务商。
          </p>
        </div>
        <div class="about-top-r">
          <img src="../assets/img/33402.jpg" />
        </div>
      </div>
    </div>
    <!-- 企业文化 -->
    <div class="qywh" id="CultureInfo">
      <h1>企业文化</h1>
      <div class="qywh-item">
        <!-- <div class="qywh-item1">
          <span></span>
          <p class="qywh-text">企业定位</p>
        </div> -->
        <div class="qywh-item1">
          <span><i class="el-icon-magic-stick"></i></span>
          <p class="qywh-text">企业理念</p>
          <p class="qywh_p">微创新、深创新、务实创新</p>
        </div>
        <div class="qywh-item1">
          <span><i class="el-icon-medal"></i></span>
          <p class="qywh-text">企业使命</p>
          <p class="qywh_p">
            科技助力金融，永远作专业投资机构的可行选择、可靠托付，
            助其创造无限可能。
          </p>
        </div>
        <div class="qywh-item1">
          <span><i class="el-icon-star-off"></i></span>
          <p class="qywh-text">企业愿景</p>
          <p class="qywh_p">成为中国金融机构最优秀的科技伙伴！</p>
        </div>
      </div>
    </div>
    <!-- 发展历程 -->
    <!-- <div class="DevelopmentHistory" id="history">
      <div class="DevelopmentHistory_cont">
        <h1>发展历程</h1>
      </div>
    </div> -->
    <!-- 底部 -->
    <footer2></footer2>
  </div>
</template>

<script>
import daohang from "../components/nav.vue";
import footer2 from "../components/footer.vue";
import banner2 from "../components/banner.vue";
export default {
  name: "App",
  data() {
    return {
      h1: "了解我们",
      P1: "",
    };
  },
  components: {
    daohang,
    footer2,
    banner2,
  },
  created() {
    this.$nextTick(() => {
      this.toLocal();
    });
  },
  mounted() {
    let _this = this;
    _this.$nextTick(function () {
      window.addEventListener("scroll", _this.handleScroll);
    });
  },
  methods: {
    toLocal() {
      //查找存储的锚点id
      let Id = localStorage.getItem("toId");
      let toElement = document.getElementById(Id);
      //锚点存在跳转
      if (Id) {
        toElement.scrollIntoView();
        localStorage.setItem("toId", "");
      }
    },
  },
};
</script>

<style scoped>
.el-icon-star-off,
.el-icon-magic-stick,
.el-icon-medal {
  font-size: 50px;
  line-height: 90px;
  color: #fbf5e7;
}
.GroupOverview {
  width: 100%;
  margin: 0 auto;
}
.about {
  height: 430px;
  padding: 100px;
  background: #f3ecec;
}

.about-top {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.about-top-l {
  width: 611px;
  height: 442px;
}

.about-top-l > p {
  font-size: 16px;
  line-height: 40px;
}

.about-top-r img {
  width: 487px;
  height: 400px;
}

.item {
  top: 236px;
  position: relative;
}

.item2 {
  top: 158px;
  left: 328px;
  position: relative;
}

.item3 {
  top: 79px;
  left: 645px;
  position: relative;
}

.item4 {
  left: 1049px;
  position: relative;
}

.item-1 {
  color: rgba(35, 51, 127, 1);
  font-size: 60px;
}

.item-2 {
  position: absolute;
  color: rgba(35, 51, 127, 1);
  font-size: 16.8px;
}

.item-3 {
  position: absolute;
  left: 88px;
  top: 41px;
  color: rgba(102, 102, 102, 1);
  font-size: 20px;
  width: 100px;
}

.item2-3 {
  position: relative;
  color: rgba(102, 102, 102, 1);
  left: 18px;
  font-size: 20px;
}

.item3-3 {
  position: relative;
  color: rgba(102, 102, 102, 1);
  left: 18px;
  font-size: 20px;
}

.item4-3 {
  position: relative;
  color: rgba(102, 102, 102, 1);
  left: 18px;
  font-size: 20px;
}

.qywh {
  padding: 0 100px;
  height: 598px;
  background: linear-gradient(
    to bottom right,
    #cc0923,
    #792626,
    #cc0923,
    #792626
  );
}

.qywh h1 {
  color: #fbf5e7;
  font-size: 32px;
  line-height: 134px;
  text-align: center;
  margin: 0;
}

.qywh-item {
  margin: 30px 40px;
  justify-content: space-evenly;
  display: flex;
}

.qywh-item1 {
  width: 240px;
  height: 348px;
  padding: 20px;
  text-align: center;
  background-color: #f3ecec;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

.qywh-item1 span {
  display: inline-block;
  border-radius: 100%;
  width: 90px;
  height: 90px;
  background: #cc0923;
}

.qywh_p {
  text-align: left;
  margin: 20px;
}
.qywh-text {
  color: #7e2829;
  font-size: 24px;
  margin-top: 20px;
}

/* 发展历程 */
.DevelopmentHistory {
  width: 1920;
  height: 527px;
  background-image: url("../assets/img/fazhan_bg.png");
}
.DevelopmentHistory_cont {
  width: 1200px;
  margin: 0 auto;
}
.DevelopmentHistory_cont > h1 {
  text-align: center;
  margin-top: 64px;

  font-size: 32px;
  font-weight: 600;
  color: #444444;
  line-height: 45px;
}
</style>
