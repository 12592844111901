<template>
  <!-- star -->

  <div class="kejipage_wrap">
    <nav2></nav2>
    <banner2 :bannerH="h1" :bannerP="P1"></banner2>
    <!-- 教育 -->
    <div class="Education">
      <div class="Education_cont">
        <div class="Education_item">
          <div class="Education_item1">
            <img src="../assets/img/29655116.jpg" alt="" />
            <div class="Education_item_text">
              <p>移动端基金交易系统</p>
              <span
                >&emsp;&emsp;移动端基金交易系统由前端H5交易系统和中台管理系统构成，是一个综合性互联网金融平台，当前版本主要针对手机移动端的使用场景进行打造。<br />&emsp;&emsp;交易系统前端部分旨在以客户服务为中心，打造一个极致、标准、合规的交易平台。基金直销和基金代销对基金交易系统的需求是有细分区别的，基金直销交易系统主要定位在对基金代销渠道的业务场景补充，简洁高效合规且功能（账户、交易、查询）完善，宣传自身基金品牌的同时兼顾第三方引流支持；代销的交易系统则更加侧重营销活动的能力。无论直销也好，代销也罢，都要求交易系统必须是一个高扩展性、高可靠性的平台。<br />&emsp;&emsp;中台管理系统后接直销API、数据中心API、数据中心数据库报表、CMS网站API、第三方数据源，为基金移动交易、引流交易、APP交易、微信公众号营运提供业务支持，本身也支持互金管理人员登录实施营销管理工作和业务配置，是系统建设的核心。</span
              >
            </div>
          </div>
          <div class="Education_item1">
            <div class="Education_item_text">
              <p>金融行业RPA产品实施</p>
              <span
                >&emsp;&emsp;涨势科技的RPA产品有10余年RPA产品的沉淀，已与300+企业客户积累了丰富的行业经验，其中有200+的金融行业的客户使用，拥有排他性的市场份额，为金融行业RPA产品的领导者。<br />&emsp;&emsp;在金融行业，许多系统部署时间较长，存在诸如流程复杂、系统之间无法打通、数据割裂的痛点，需要大量人工操作，新增需求开发周期长，难以适应当前市场环境的变化，RPA可以帮助金融从业人员快速地改善业务流程，大幅缩短开发周期。除此之外，RPA还可以简化流程降低风险，人为操作业务流程时会存在大量风险，如盗取数据、篡改数据、输入错误数据等，但是RPA作为数字员工可以基于一定规则自动执行大量重复、枯燥的业务，保证处理的准确度。得益于人工智能的快速发展，可以通过RPA+AI来应对那些繁琐、复杂的非结构化数据，完成复杂应用场景的流程替代。使企业人力资源得到优化和释放，从而推动人员去执行企业具有更高价值的工作。</span
              >
            </div>
            <img src="../assets/img/29655114.jpg"  alt="" />
          </div>
          <div class="Education_item1">
            <img src="../assets/img/29655117.jpg" alt="" />
            <div class="Education_item_text">
              <p>人力资源外包服务</p>
              <span
                >&emsp;&emsp;涨势科技拥有多年金融行业服务经验的业务专家，和专业细心的HR专家，能为基金、券商、保险资管、银行、协会等客户提供更为专业的人力资源外包服务。除了自有多年项目经验的开发人员，公司专家积累的业务经验也能够提前为人力外包工程师提升更多的竞争力和服务能力。</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- footer -->
    <footer2></footer2>

    <!-- end -->
  </div>
</template>

<script>
import footer2 from "../components/footer.vue";
import banner2 from "../components/banner.vue";
import nav2 from "../components/nav.vue";
export default {
  name: "keji",
  components: {
    footer2,
    nav2,
    banner2,
  },
  data() {
    return {
      h1: "与我们合作",
      P1: "",
    };
  },
  methods: {},
};
</script>
<style scoped>
@charset "utf-8";
/*=========================Reset_start==========================*/
body,
h1,
h2,
h3,
h4,
h5,
h6,
div,
p,
dl,
dt,
dd,
ol,
ul,
li,
form,
table,
th,
td,
a,
img,
span,
strong,
var,
em,
input,
textarea,
select,
option {
  margin: 0;
  padding: 0;
}
/*去内外边距*/
html,
body {
  text-align: left;
}
/*字体 文本居左*/
ul {
  list-style: none;
}
/*去ul li 小点*/
img {
  border: 0 none;
}
/*img IE6 兼容问题*/
input,
select,
textarea {
  outline: 0;
}
/*去除点击蓝框*/
textarea {
  resize: none;
  overflow: auto;
}
/*锁定多行文本框大小 与文字超出出现滚动条*/
table {
  border-collapse: collapse;
  border-spacing: 0;
}
/*表格边框合并 边框间距0*/
th,
strong,
var,
em {
  font-weight: normal;
  font-style: normal;
}
/*特殊文字粗细 样式显示正常*/
a {
  text-decoration: none;
}
/*a标签去下划线*/
.clearfix:after {
  clear: both;
  content: "";
  display: block;
  height: 0;
}
/*清浮动*/
a {
  color: #000000;
}
/*==========================Reset_End===========================*/

.kejipage_wrap {
  width: 100%;
  margin: 0 auto;
  height: 1000px;
}
/* 教育 */
.Education {
  width: 100%;
  background: #f3ecec;
}
.Education_cont {
  padding: 70px 120px;
}
.Education_item {
  margin-top: 45px;
}
.Education_item1 {
  margin-bottom: 45px;
  display: flex;
  background: #ffffff;
  padding: 30px 10px;
  border-radius: 10px;
  justify-content: space-around;
}
.Education_item1 img {
  width: 460px;
}
.Education_item_text p {
  margin-top: 10px;
  font-size: 24px;

  font-weight: 500;
  color: #7e2829;
  line-height: 33px;
}
.Education_item_text span {
  width: 568px;
  height: 140px;
  margin: 24px 0 10px 0;
  display: inline-block;
  font-size: 14px;
  font-weight: 400;
  color: #444444;
  line-height: 28px;
}
.Education_item1_block {
  width: 550px;
  display: flex;
  flex-wrap: wrap;
  margin-left: 86px;
}

.Education_item1_block .div3 {
  margin: 40px 24px 38px 0;
  line-height: 40px;
  text-align: center;
  width: 172px;
  height: 40px;
  background: #ffffff;
  border-radius: 4px;
  border: 1px solid #d7d7d7;
}
.Education_item1_block .div4 {
  margin-top: -10px;
}
.Education_item1_block .div5 {
  margin-top: -10px;
}

.Education_item1_block div {
  margin: 40px 24px 38px 0;
  line-height: 40px;

  text-align: center;
  width: 140px;
  height: 40px;
  background: #ffffff;
  border-radius: 4px;
  border: 1px solid #d7d7d7;
}
</style>
