import Vue from "vue";
import "./plugins/axios";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

Vue.config.productionTip = false;
Vue.use(ElementUI);
new Vue({
	router,
	store,
	render: (h) => h(App),
}).$mount("#app");

// title
const defaultTitle = "默认 title";
router.beforeEach((to, from, next) => {
	document.title = to.meta.title ? to.meta.title : defaultTitle;
	next();
});
