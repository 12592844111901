<template>
  <div>
    <div class="wrap">
      <div class="logo">
        <img :src="logoimg" />
      </div>

      <div class="nav">
        <div
          class="nav_btn"
          v-for="(item, index) in navList"
          :key="index"
          @click="funyuming(item.url)"
        >
          {{ item.title }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Nav",
  data() {
    return {
      navList: [
        { title: "首页", url: "/" },
        { title: "公司业务", url: "/keji" },
        { title: "关于我们", url: "/jituangaikuo" },
        { title: "联系我们", url: "/lianxi" },
      ],
      logoimg: require("../assets/img/2966486001.jpg"),
    };
  },
  methods: {
    funyuming(msg) {
      this.$router.push(msg);
    },
    funRouter() {
      this.$router.push("/keji");
    },
  },
};
</script>
<style scoped>
.wrap {
  width: 100%;
  height: 80px;
  background-color: #471f1f;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.wrap > .logo > img {
  width: 181px;
}

.nav {
  display: flex;
  color: #fbf5e7;
  font-size: 20px;
}

.nav_btn {
  margin-left: 40px;
  cursor: pointer;
}
</style>
