import Vue from "vue";
import VueRouter from "vue-router";
import index from "../views/index.vue";
import jituangaikuo from "../views/jituangaikuo.vue";
import keji from "../views/keji.vue";
import lianxi from "../views/lianxiwomen.vue";

// 报错解决
// 获取原型对象push函数
const originalPush = VueRouter.prototype.push;

// 获取原型对象replace函数
const originalReplace = VueRouter.prototype.replace;

// 修改原型对象中的push函数
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

// 修改原型对象中的replace函数
VueRouter.prototype.replace = function replace(location) {
  return originalReplace.call(this, location).catch((err) => err);
};

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "index",
    component: index,
    meta: {
      title: "首页",
    },
  },
  {
    path: "/jituangaikuo",
    name: "jituangaikuo",
    component: jituangaikuo,
    meta: {
      title: "关于我们",
    },
  },
  {
    path: "/keji",
    name: "keji",
    component: keji,
    meta: {
      title: "公司业务",
    },
  },
  {
    path: "/lianxi",
    name: "lianxi",
    component: lianxi,
    meta: {
      title: "联系我们",
    },
  },
];

const router = new VueRouter({
  routes,
  // 跳转页面后 回到顶部
  scrollBehavior() {
    return {
      x: 0,
      y: 0,
    };
  },
});

export default router;
