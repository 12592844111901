<template>
  <!-- star -->

  <div class="homepage_wrap">
    <!-- banner图和导航栏 -->
    <Nav></Nav>
    <div class="banner_nav">
      <el-carousel height="572px">
        <el-carousel-item v-for="(item, index) in bannerImg" :key="index">
          <div class="banner_nav_bg">
            <img :src="item.img" alt="" />
          </div>
          <div class="banner_title_wrap">
            <div class="banner_title_center">
              <p v-text="item.bannerTitle"></p>
              <div v-text="item.bannerContent"></div>
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="GroupIndustry">
      <div class="GroupIndustry_top">公司业务</div>
      <el-carousel
        :interval="60000"
        type="card"
        height="600px"
        indicator-position="none"
        arrow="never"
      >
        <el-carousel-item v-for="(item, index) in businessList" :key="index">
          <img :src="item.jituanimg" />
          <p v-text="item.jituanTitle"></p>
          <span v-html="item.jituanContent"></span>
        </el-carousel-item>
      </el-carousel>
    </div>
    <!-- 关于企业 -->
    <div class="AboutEnterprises">
      <div class="AboutEnterprises_cont">
        <!-- top -->
        <div class="AboutEnterprises_cont_top">
          <!-- top_left -->
          <div class="AboutEnterprises_cont_top_left">
            <h1>关于我们</h1>
            <p>
              北京涨势科技有限公司（简称“涨势科技”）成立于北京。涨势科技是一家深耕金融行业的科技公司。公司致力于推动大数据、云计算、人工智能等新兴技术在专业领域里的应用。针对各类行业特点，涨势科技开发了解决行业痛点的专业级别的智能分析方法和分析模型，为投资公司，银行等各类企业和机构在数据结构化、商机营销、舆情分析、以及智能投顾、综合监控、客户管理等决策方面提供精准信息分析服务。
            </p>
            <p>
              涨势科技的团队在资管行业服务多年，为帮助金融从业人员快速地改善业务流程,大幅缩短工作周期,涨势科技与国内一家专注于企业级RPA技术的人工智能公司——金智维信息科技有限公司合作。以“RPA+AI+大数据+资管经验”为核心理念，为金融行业制定更适用的业务解决方案。致力于做最专业的资管行业解决方案服务商。
            </p>
            <router-link to="/jituangaikuo">
              <div class="AboutEnterprises_cont_top_left_btn">
                <span>了解更多</span>
                <img src="../assets/img/guanyujiantou.png" alt="" />
              </div>
            </router-link>
          </div>
          <!-- top_right -->
          <div class="AboutEnterprises_cont_top_right">
            <img src="../assets/img/guanyuyou.png" alt="" />
            <div></div>
          </div>
        </div>
      </div>
    </div>
    <div class="partners">
      <p>合作伙伴</p>
      <div class="partners_img">
        <a :href="item.link" v-for="(item, index) in cooperateImg" :key="index" target="_blank">
          <img :src="item.url" alt="" />
        </a>
      </div>
    </div>
    <div class="CooperativePartner">
      <p>专业，离成功更近!</p>
      <span
        >涨势科技的每一个主营业务方向都有从业经验丰富的专家负责业务把控，由内部的专业带出对外的核心竞争力。</span
      >
    </div>
    <footer2></footer2>
  </div>
</template>

<script>
import Nav from "../components/nav";
import footer2 from "../components/footer.vue";
export default {
  name: "index",
  components: {
    Nav,
    footer2,
  },
  data() {
    return {
      businessList: [
        {
          jituanimg: require("../assets/img/29655116.jpg"),
          jituanTitle: "移动端基金交易系统",
          jituanContent:
            "&emsp;&emsp;移动端基金交易系统由前端H5交易系统和中台管理系统构成,是一个综合性互联网金融平台,当前版本主要针对手机移动端的使用场景进行打造。<br>&emsp;&emsp;中台管理系统后接直销API、数据中心API、数据中心数据库报表、CMS网站API、第三方数据源,为基金移动交易、引流交易、APP交易、微信公众号营运提供业务支持,本身也支持互金管理人员登录实施营销管理工作和业务配置,是系统建设的核心。",
        },
        {
          jituanimg: require("../assets/img/29655114.jpg"),
          jituanTitle: "金融行业RPA产品实施",
          jituanContent:
            "&emsp;&emsp;涨势科技的RPA产品有10余年RPA产品的沉淀,已与300+企业客户积累了丰富的行业经验,其中有200+的金融行业的客户使用,拥有排他性的市场份额,为金融行业RPA产品的领导者。<br>&emsp;&emsp;在金融行业,许多系统部署时间较长,存在诸如流程复杂、系统之间无法打通、数据割裂的痛点,需要大量人工操作,新增需求开发周期长,难以适应当前市场环境的变化,RPA可以帮助金融从业人员快速地改善业务流程,大幅缩短开发周期。",
        },
        {
          jituanimg: require("../assets/img/29655117.jpg"),
          jituanTitle: "人力资源外包服务",
          jituanContent:
            "&emsp;&emsp;涨势科技拥有多年金融行业服务经验的业务专家,和专业细心的HR专家,能为基金、券商、保险资管、银行、协会等客户提供更为专业的人力资源外包服务。除了自有多年项目经验的开发人员,公司专家积累的业务经验也能够提前为人力外包工程师提升更多的竞争力和服务能力。",
        },
      ],
      bannerImg: [
        {
          img: require("../assets/img/55571.jpg"),
          bannerTitle: "与您一同品赏",
          bannerContent: "每一轮业绩的涨势",
        },
        {
          img: require("../assets/img/55569.jpg"),
          bannerTitle: "只为你，而卓越",
          bannerContent: "Only for you, and excellence",
        },
        {
          img: require("../assets/img/55571.jpg"),
          bannerTitle: "与您一同品赏",
          bannerContent: "每一轮业绩的涨势",
        },
        {
          img: require("../assets/img/55569.jpg"),
          bannerTitle: "只为你，而卓越",
          bannerContent: "Only for you, and excellence",
        },
      ],
      cooperateImg: [
        {
          url:require("../assets/img/jinzhiwei.svg"),
          link:"http://www.kingsware.cn/",
        },
        {
          url:require("../assets/img/hhxx@4x.png"),
          link:"https://www.intsig.com/",
        },
      ],
    };
  },
  computed: {
    title() {
      return this.$store.state.title;
    },
  },
  methods: {
    funyuming(msg) {
      this.$router.push(msg);
    },
    newsArticle(index) {
      this.$router.push("/newsxiangqing" + index);
    },
    funRouter() {
      this.$router.push("/keji");
    },
  },
};
</script>
<style scoped>
@charset "utf-8";
/*=========================Reset_start==========================*/
body,
h1,
h2,
h3,
h4,
h5,
h6,
div,
p,
dl,
dt,
dd,
ol,
ul,
li,
form,
table,
th,
td,
a,
img,
span,
strong,
var,
em,
input,
textarea,
select,
option {
  margin: 0;
  padding: 0;
}
/*去内外边距*/
html,
body {
  text-align: left;
}
/*字体 文本居左*/
ul {
  list-style: none;
}
/*去ul li 小点*/
img {
  border: 0 none;
}
/*img IE6 兼容问题*/
input,
select,
textarea {
  outline: 0;
}
/*去除点击蓝框*/
textarea {
  resize: none;
  overflow: auto;
}
/*锁定多行文本框大小 与文字超出出现滚动条*/
table {
  border-collapse: collapse;
  border-spacing: 0;
}
/*表格边框合并 边框间距0*/
th,
strong,
var,
em {
  font-weight: normal;
  font-style: normal;
}
/*特殊文字粗细 样式显示正常*/
a {
  text-decoration: none;
}
/*a标签去下划线*/
.clearfix:after {
  clear: both;
  content: "";
  display: block;
  height: 0;
}
/*清浮动*/
a {
  color: #000000;
}
/*==========================Reset_End===========================*/

.homepage_wrap {
  width: 100%;
  margin: 0 auto;
}

.banner_nav {
  width: 100%;
  height: 572px;
  position: relative;
}
.el-carousel__indicator--horizontal {
  padding: 20px 4px;
}

/* title */

.banner_title_wrap {
  width: 100%;
  position: absolute;
  bottom: 200px;
}

.banner_title_center {
  text-align: center;
  width: 500px;
  margin: 0 auto;
  font-size: 40px;
  color: #ffffff;
  background-color: #99000040;
  line-height: 110px;
  letter-spacing: 1px;
  height: 200px;
}

.banner_title_center div {
  padding-top: 14px;
  font-size: 25px;
  line-height: 24px;
  letter-spacing: 4px;
}

.banner_title_center div span {
  margin: 0 18px;
}

.GroupIndustry {
  height: 750px;
  padding: 0 135px;
  background: #cda49e;
  color: #fbf5e7;
}
.GroupIndustry_top {
  text-align: center;
  height: 100px;
  padding-top: 30px;
  line-height: 75px;
  color: #7e2829;
  font-size: 36px;
}
.GroupIndustry .el-carousel__item {
  width: 580px;
  height: 470px;
  background: linear-gradient(#9c2632, #c51f1fcc, #c51f1f);
  padding: 20px;
  border-radius: 10px;
}
.GroupIndustry .el-carousel__item img {
  text-align: center;
  width: 400px;
  height: 266.66px;
  margin-left: 14%;
}
.GroupIndustry .el-carousel__item p {
  text-align: center;
  margin-top: 20px;
  margin-bottom: 10px;
}
/* 关于企业 */
.AboutEnterprises {
  width: 100%;
  height: 700px;
  background: url("../assets/img/red.jpg") no-repeat center center;
  background-size: 100% 100%;
}
.AboutEnterprises_cont {
  width: 1200px;
  margin: 0 auto;
  height: 100%;
}
.AboutEnterprises_cont_top {
  display: flex;
  justify-content: space-between;
}
.AboutEnterprises_cont_top_left {
  margin-top: 60px;
  width: 600px;
}
.AboutEnterprises_cont_top_left > h1 {
  font-size: 36px;
  font-weight: 600;
  color: #fbf5e7;
  line-height: 50px;
}
.AboutEnterprises_cont_top_left p {
  margin-top: 36px;
  font-size: 16px;
  font-weight: 400;
  color: #fbf5e7;
  line-height: 40px;
}
.AboutEnterprises_cont_top_left_btn {
  width: 160px;
  height: 40px;
  border: 1px solid rgba(255, 255, 255, 0.8);
  margin-top: 45px;
  display: flex;
  align-items: center;
}
.AboutEnterprises_cont_top_left_btn img {
  width: 24px;
  height: 24px;
  margin-left: 8px;
}
.AboutEnterprises_cont_top_left_btn span {
  margin-left: 32px;
  font-size: 16px;
  font-weight: 400;
  color: #fbf5e7;
  line-height: 22px;
}
.AboutEnterprises_cont_top_right {
  position: relative;
  width: 487px;
  height: 517px;
  margin-top: 115px;
}
.AboutEnterprises_cont_top_right img {
  position: absolute;
  z-index: 1;
}
.AboutEnterprises_cont_top_right Div {
  width: 317px;
  height: 408px;
  background: #3491e5;
  position: absolute;
  top: 64px;
  left: -33px;
}
.partners {
  padding: 0 135px;
  background: #f3ecec;
  border-bottom: 4px solid #471f1f;
}
.partners_img {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap
}
.partners p {
  color: #7e2829;

  text-align: center;
  padding: 20px;
  font-size: 36px;
}
.partners img {
  width: 200px;
  margin: 20px;
}
.CooperativePartner {
  height: 350px;
  background: #f3ecec;
  padding: 0 135px;
  color: #7e2829;
  text-align: right;
}
.CooperativePartner p {
  font-size: 36px;
  font-weight: 600;
  line-height: 50px;
  padding: 60px 60px 20px;
}
.CooperativePartner span {
  display: inline-block;
  font-size: 18px;
  line-height: 50px;
  width: 590px;
  padding-right: 60px;
}
</style>
